var C = Object.defineProperty;
var x = (t, e, s) => e in t ? C(t, e, { enumerable: !0, configurable: !0, writable: !0, value: s }) : t[e] = s;
var h = (t, e, s) => (x(t, typeof e != "symbol" ? e + "" : e, s), s);
const T = {
  stringify: (t) => t,
  parse: (t) => t
}, V = {
  stringify: (t) => `${t}`,
  parse: (t) => parseFloat(t)
}, N = {
  stringify: (t) => t ? "true" : "false",
  parse: (t) => /^[ty1-9]/i.test(t)
}, P = {
  stringify: (t) => t.name,
  parse: (t, e, s) => {
    const c = (() => {
      if (typeof window < "u" && t in window)
        return window[t];
      if (typeof global < "u" && t in global)
        return global[t];
    })();
    return typeof c == "function" ? c.bind(s) : void 0;
  }
}, $ = {
  stringify: (t) => JSON.stringify(t),
  parse: (t) => JSON.parse(t)
}, A = {
  string: T,
  number: V,
  boolean: N,
  function: P,
  json: $
};
function J(t) {
  return t.replace(
    /([a-z0-9])([A-Z])/g,
    (e, s, c) => `${s}-${c.toLowerCase()}`
  );
}
const d = Symbol.for("r2wc.render"), g = Symbol.for("r2wc.connected"), l = Symbol.for("r2wc.context"), p = Symbol.for("r2wc.props");
function _(t, e, s) {
  var k, O, j;
  e.props || (e.props = t.propTypes ? Object.keys(t.propTypes) : []);
  const c = Array.isArray(e.props) ? e.props.slice() : Object.keys(e.props), b = {}, m = {}, w = {};
  for (const r of c) {
    b[r] = Array.isArray(e.props) ? "string" : e.props[r];
    const u = J(r);
    m[r] = u, w[u] = r;
  }
  class S extends HTMLElement {
    constructor() {
      super();
      h(this, k, !0);
      h(this, O);
      h(this, j, {});
      h(this, "container");
      e.shadow ? this.container = this.attachShadow({
        mode: e.shadow
      }) : this.container = this, this[p].container = this.container;
      for (const n of c) {
        const f = m[n], o = this.getAttribute(f), i = b[n], a = i ? A[i] : null;
        a != null && a.parse && o && (this[p][n] = a.parse(o, f, this));
      }
    }
    static get observedAttributes() {
      return Object.keys(w);
    }
    connectedCallback() {
      this[g] = !0, this[d]();
    }
    disconnectedCallback() {
      this[g] = !1, this[l] && s.unmount(this[l]), delete this[l];
    }
    attributeChangedCallback(n, f, o) {
      const i = w[n], a = b[i], y = a ? A[a] : null;
      i in b && (y != null && y.parse) && o && (this[p][i] = y.parse(o, n, this), this[d]());
    }
    [(k = g, O = l, j = p, d)]() {
      this[g] && (this[l] ? s.update(this[l], this[p]) : this[l] = s.mount(
        this.container,
        t,
        this[p]
      ));
    }
  }
  for (const r of c) {
    const u = m[r], n = b[r];
    Object.defineProperty(S.prototype, r, {
      enumerable: !0,
      configurable: !0,
      get() {
        return this[p][r];
      },
      set(f) {
        this[p][r] = f;
        const o = n ? A[n] : null;
        if (o != null && o.stringify) {
          const i = o.stringify(f, u, this);
          this.getAttribute(u) !== i && this.setAttribute(u, i);
        } else
          this[d]();
      }
    });
  }
  return S;
}
export {
  _ as default
};
